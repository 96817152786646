import { User } from '../core/user';

const ADMIN_ROLE = 'Admin';
const DELEGATE_ROLE_PREFIX = 'Delegate{';

export const hasAdminLikeRole = () => {
    let hasAdminLikeRole = false;

    for (let role of (new User()).roles) {
        if (role === ADMIN_ROLE) {
            hasAdminLikeRole = true;
        } else if (role.startsWith(DELEGATE_ROLE_PREFIX)) {
            hasAdminLikeRole = true;
        }
    }

    return hasAdminLikeRole;
}